@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

*{
  margin: 0;
  padding: 0;
}
button{
  cursor: pointer;
}




/* *** */
.homeProducts{
  display: block;
  padding: 32px;
  font-family: 'Quicksand', sans-serif;
}
.homeProductsDark{
  display: block;
  padding: 32px;
  background-color: black;
}

@media (min-width : 768px) {
  .homeProducts{
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    font-family: 'Quicksand', sans-serif;
  }
  .lessFour , .lessFourDark {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin: 5px;
  }
  .homeProductsDark{
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    color: white;
    font-family: 'Quicksand', sans-serif;
  }
  li{
    margin-left: 20px;
    list-style-type: none;
    font-family: 'Quicksand', sans-serif;
  }
  #navTitle{
    margin-left: 5px;
    list-style-type: none;
    height: 20px;
    font-family: 'Quicksand', sans-serif;
  }
}


.homeChild{
  /* background-color: rgb(31, 46, 25); */
  border: solid 1px black;
  margin-bottom: 16px;
  padding: 16px;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  font-family: 'Quicksand', sans-serif;
}
.homeChildDark{
  /* background-color: rgb(31, 46, 25); */
  border: solid 1px white;
  margin-bottom: 16px;
  padding: 16px;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  font-family: 'Quicksand', sans-serif;
}
/* .homeProducts{
  display: flex;
  justify-content: center;
  margin: 10px;
  text-align: center;
  border: solid 1px black;
  padding: 10px;
  height: auto;
  align-items: center;
  flex-wrap: wrap;
} */

.mainImage{
  height: 100px;
  padding: 20px 0px;
}

#title{
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  padding: 20px 0px;
  font-family: 'Quicksand', sans-serif;

}





/* View css */
.mainView{
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 100px;
  font-family: 'Quicksand', sans-serif;
}

.addView{
  justify-content: center;
  align-items: flex-end;
  padding: auto;
  font-family: 'Quicksand', sans-serif;
}

#imgView{
  height: 200px;
}

.viewCont{
  margin: 20px 20px;
  height: 100vh;
  font-family: 'Quicksand', sans-serif;
}
.viewContDark{
  padding: 20px 20px;
  background-color: black;
  height: 100vh;
  width: auto;
  color: white;
  font-family: 'Quicksand', sans-serif;
}
.topViewDark{
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 50px;
  font-family: 'Quicksand', sans-serif;
}
.topView{
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 50px;
  font-family: 'Quicksand', sans-serif;
}

#cross{
  font-size: 20px;
  cursor: pointer;
}

#descDetail{
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* text-align: center; */
}

#descId{
  margin-bottom: 20px;
}


/* *** */


/* Cart css */

.cartMain{
  display: flex;
  justify-content: center;
  margin: 50px;
}


/* *** */



/* contact css */

.welcome{
  justify-content: center;
  align-items: center;
  text-align: center;
  /* margin: 50px; */
  padding: 20px;
  font-family: 'Quicksand', sans-serif;
}
.welcomeDark{
  justify-content: center;
  align-items: center;
  text-align: center;
  /* margin: 50px; */
  padding: 20px;
  font-family: 'Quicksand', sans-serif;
  background-color: black;
  color: white;
}
.about{
  height: 100vh;
}

@media (min-width : 768px){
  .contact, .welcome{
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
  }
}

.contact{
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.linkedin-img{
  width: 80px;
}

.contact-dark{
  background-color: black;
  height: 100vh;
}

/* ** */


/* About css */
#gh{
  list-style-type: none;
  text-decoration: none;
}

/* *** */

.cartEmp{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 100px;
}
.cartEmpDark{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 100px;
  background-color: black;
  color: white;
}

.darkReturn{
  height: 100vh;
  background-color: black;
  color: white;
}
.return{
  height: 100vh;
  background-color: white;
  color: black;
}

.footer{
  background-color: #dee2e6;
}

.footer-black{
  background-color: #212529;
  color: #dee2e6;
}

.load{
  background-color: black;
}